@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Nunito", "Segoe UI" arial;
}

body {
  background-color: #f6f6f6;
}

/* Scroll designing #################################################################*/
::-webkit-scrollbar {
  width: 0.4em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: #201e1938;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #104861, #3880ff);
  border-radius: 10px;
}

.gradient__scrollar::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #104861, #3880ff);
  border-radius: 10px;
}

.gradient__scrollar::-webkit-scrollbar {
  display: none;
}

.gray__scrollar::-webkit-scrollbar-thumb {
  display: none !important;
}

.gray__scrollar::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.gray__scrollar:hover::-webkit-scrollbar-thumb {
  background: lightgray !important;
  border-radius: 10px;
  display: block !important;
}

/* common classes ##########################################################*/
/* Loader start Styling */
.position_ {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  /* background: linear-gradient(to right, #92a6d5a1, #005eff6e); */
  background: #0000002d;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader Styling end*/

.common_border {
  border: 1px dashed #3880ff;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.input_div > input {
  margin: 0 0 1rem 0;
  font: 400 0.8rem "Nunito", sans-serif;
}

.table_width_fax {
  width: 500px;
}

.card_width_manage {
  width: 60%;
}

.card_height_manage {
  height: 40px;
}

.extra_padding {
  padding: 1rem 1rem 2rem 1rem;
}

.logo_width {
  width: 50px;
}

.password_input {
  border: none;
  outline: none;
  width: 90%;
}

.welcome_div {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #edf5fb; */
}

.common_container {
  width: 100%;
  margin: auto;
}

.common_content {
  width: 100%;
  padding: 1.5rem 0;
  /* height: calc(100vh - 115px); */
  overflow: auto;
  margin-bottom: 4rem;
}

.flex_design {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.common_btn {
  background: linear-gradient(to right, #104861, #3880ff);
  width: 100%;
  border: none;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  font: 700 0.8rem "Roboto", sans-serif;
  cursor: pointer;
}

.common_btn:hover {
  background: linear-gradient(to right, #367df8, #0e3e55)!important;
  color: #fff !important;
}

.common_btn2 {
  background: #e3eaef;
  width: 100%;
  border: none;
  height: 40px;
  color: #222;
  border-radius: 5px;
  outline: none;
  font: 700 0.8rem "Roboto", sans-serif;
  cursor: pointer;
}

.common_btn2:focus {
  background: linear-gradient(to right, #367df8, #0e3e55);
  color: #ffffff;
}

/* ((((((((((((((((((((For dictate froms classes Start()))))))))))))) */
.title_font {
  background: linear-gradient(90deg, #104861, #3880ff);
  margin: 0.5rem 0;
  text-align: center;
  padding: 0.5rem 0;
  color: #fff;
  font: 700 1rem "Nunito", sans-serif;
}

.sub_title_font {
  font: 600 1rem "Nunito", sans-serif;
  padding: 0.4rem;
  margin: 0.5rem 0;
  background: linear-gradient(
    90deg,
    #104861,
    rgba(32, 86, 178, 0.698)
  ) !important;
  color: #fff;
}

.mini_title_font {
  background: gainsboro;
  margin: 2px 0;
  padding: 0.3rem;
  font: 500 0.9rem "Nunito", sans-serif;
}

.modal_wrapper {
  /* background: #0000002c; */
  width: 650px;
}
/* ((((((((((((((((((((For dictate froms classes End()))))))))))))) */
.large_font {
  font: 800 1.2rem "Nunito", sans-serif;
  color: #104861;
}

.medium_font {
  font: 500 0.8rem "Nunito", sans-serif;
}

.small_font {
  font: 800 0.8rem "Nunito", sans-serif;
  color: #104861;
}

.selectFont {
  font: 800 0.8rem "Nunito", sans-serif;
  color: #6c6c6c;
}

.select_fields {
  width: 100%;
}

.ant-select-multiple .ant-select-selector {
  height: 40px !important;
  border: 1px solid #3881ff6c !important;
  border-radius: 5px !important;
}

.css-13cymwt-control {
  height: 40px;
  border: 1px solid #3881ff6c !important;
  border-radius: 5px !important;
}

.input_fields {
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0 1rem;
  border: 1px solid #3881ff6c;
  border-radius: 5px;
  background: #fff;
  font-size: 0.8rem;
}

.input_fields_ {
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0 1rem;
  border: 1px solid #3881ff6c;
  border-radius: 5px;
  background: #fff;
  font-size: 0.8rem;
}

.common_background {
  background: linear-gradient(to right, #3880ff, #104861);
  height: 100vh;
  overflow: auto;
}

.common_background_ {
  background: linear-gradient(to right, #3880ff, #104861);
}

.content_list:hover {
  color: #2b3c95;
}

.block_display > span {
  display: block;
}

/* Header Section styling ###############################################*/
.header_div {
  background-color: #edf5fb;
  box-shadow: 15px 9px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 3;
}

/* .sidebar_div {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  width: 250px;
  background: #edf5fb;
  left: 0;
  padding: 1rem 0;
  transition: all 0.3s ease-in-out;
  overflow: auto;
} */

.text_none {
  display: none;
}

.header_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.herader__user__icon {
  padding: 7px !important;
}

.route_div_dark {
  background: linear-gradient(to right, #104861, #3880ff);
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
}

.route_div {
  height: 50px;
  color: #104861;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #f6f6f6 !important;
}

/* Profile Styling #######################################################*/
.profile_img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.profile_card {
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
  background: #edf5fb;
  border: none;
  padding: 2rem 1.5rem;
}

.common_form_card {
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%),
    0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
  background: #edf5fb;
  border: none;
  padding: 2rem 1.5rem;
}

/* Auth part screens styling ###########################################*/
.vi__character {
  height: 50px !important;
  border: none !important;
  box-shadow: 0px 5px 15px #0000000d;
  border-radius: 5px;
  background: #fff !important;
  /* margin: 0 5px; */
  border: 1px solid #00008033 !important;
}

.vi__character--selected {
  outline: 2px solid #3880ff;
}

.vi__wrapper {
  width: 100% !important;
}

.vi__container {
  /* gap: 10px !important; */
  width: 100% !important;
}

.barcode_img > a > img {
  width: 50% !important;
}

/*Admin Dashboard styling ##########################################################*/
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
  border: none;
}

.card_div {
  background: linear-gradient(to right, #104861, #3880ff);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
  border-radius: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  color: #fff;
  height: 100px;
  /* overflow: auto; */
  padding: 0 1rem;
}

/* Facilty styling###################################################### */
.table_div {
  background: linear-gradient(to right, #104861, #3880ff);
  color: #fff;
  height: 40px;
}

/* .ant-table-thead {
  background: -webkit-linear-gradient(to right, #104861, #3880FF);
  background: linear-gradient(to right, #104861, #3880FF);
  color: #fff;
} */
.ant-table-thead {
  background: #2b6ecd;
  color: #fff;
}

.ant-table-thead th {
  background: transparent !important;
  color: #fff !important;
}

.ant-table-thead th::before {
  display: none;
}

tbody > tr:nth-child(odd) {
  background: #edf5fb;
  height: 40px !important;
}

tr > th {
  font: 800 0.8rem "Nunito", sans-serif;
}

tr > td {
  font: 400 0.8rem "Nunito", sans-serif;
  color: #104861;
  height: 40px !important;
}

.btn_ {
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 5px;
}

.uploader_div {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.img_upload {
  border: 1.5px dashed #3881ff6c;
  padding: 3rem 0;
  text-align: center;
  border-radius: 5px;
}

.Upload_ {
  height: 70px;
  width: 70px;
  border-radius: 5px;
}

.modal_width {
  width: 30% !important;
}

.user_img2 {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: #22222234;
}

#zmmtg-root {
  display: none;
}

.border_div {
  border: 1px dashed #104861;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.chat_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Chat box styling  start*/
.chat_div {
  height: 50vh;
  width: 25%;
  margin: auto;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  top: 20%;
  left: 40%;
  /* transform: translate(-50%, -50%) !important; */
  z-index: 999 !important;
}

.chat_header {
  background: #fff;
  padding: 0.8rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.msg_box {
  height: 40vh;
  overflow: auto;
  padding: 1.5rem 0.8rem;
  /* background: red; */
}

.all_message_div {
  /* display: flex; */
  margin-bottom: 1rem;
  width: 80%;
}

.send_msg {
  background: linear-gradient(to right, #104861, #3880ff);
  padding: 1rem !important;
  color: #fff;
  /* border-radius: 50px; */
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 50px;
}

.message_text_div {
  word-break: break-all;
}

.receive_msg {
  background: linear-gradient(to right, #656d70, #577ab6);
}

.send_msg_div {
  position: absolute;
  bottom: 1%;
  padding: 0.5rem;
  width: 100%;
  background: white;
  z-index: 999999;
  margin-bottom: -13px;
}

.border_ {
  border: 1px solid #c7ccd0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  height: 40px;
  padding: 0.8rem;
}

.msg_input > input {
  width: 95%;
  border: none;
  outline: none;
}

.count_div {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 10px !important;
  width: 10px;
}

/* Chat box styling  end*/

/* Family styling############################################## */
.chat_user_img {
  width: 50px;
  height: 50px;
}

.image_manage {
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  position: relative;
}

.img_content {
  /* border: 1px solid #fff; */
  /* background: #ffffff48; */
  /* padding: 0.5rem; */
  position: absolute;
  top: 5%;
  z-index: 9999999!important;
  margin-left: 15px;
}

.banner-title-text {
  border: 1px solid #fff;
  background: #46454547;
  font: 800 2rem "Nunito", sans-serif;
  margin-bottom: 1rem;
  padding: 0 0.8rem;
  font-style: italic;
}

.banner-sub-title-text {
  background: #46454547;
  border: 1px solid #fff;
  font: 800 1rem "Nunito", sans-serif;
  padding: 0.3rem 0.8rem;
  font-style: italic;
}

.close_div {
  text-align: center;
  width: 100%;
  background: #cd4c3a;
  padding: 1rem 0;
}

/* Provider Styling*********************************######### */
.filter_btn {
  /* height: 30px; */
  width: 50px;
  /* border-radius: 50px; */
}

.side_border {
  border-right: 1px solid #fff;
  margin: 0 0.5rem;
}
.side_border:last-child {
  border-right: transparent;
}

.notes_div {
  padding: 1rem;
  color: #fff;
}

.live_chat_div {
  background: #edf5fb;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
}

.message_div {
  height: 90%;
  overflow-y: auto;
}

.live_users_div {
  overflow-x: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.height_manage {
  height: 400px;
}

.player-wrapper {
  height: 400px;
}

/* Footer styling ##########################################*/
.footer_div {
  background-color: #edf5fb;
  box-shadow: 15px 9px 25px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  padding: 1rem;
  position: fixed;
  bottom: 0%;
}

/* antd classes *************************************************/
.ant-drawer-content-wrapper {
  display: none;
}

.ant-drawer-mask {
  display: none;
}

.ant-drawer-body {
  background: #edf5fb;
  padding: 0 !important;
}

.dropdown-toggle::after {
  display: none;
}

.ant-modal-mask {
  background: linear-gradient(to right, #104761c9, #3881ff75);
}

.ant-menu-submenu-popup.ant-menu-submenu {
  background: #fff;
  padding: 0%;
}

.inner_header_style_width {
  width: 90%;
  margin: auto;
  align-items: center;
}

.patient_list_items {
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 0%;
}

/* provider fax */
.faxNumberDropDwn select {
  outline: none;
  border: 1px solid #3880ff;
  padding: 6px 7px;
  border-radius: 5px;
  width: 100%;
  margin: 18px 0px;
}

.highcharts-title {
  color: #0e3e55 !important;
  fill: #0e3e55 !important;
}

.highcharts-background {
  fill: transparent !important;
}

.faxform select {
  outline: none;
  border: 1px solid #3880ff;
  padding: 6px 7px;
  border-radius: 5px;
  width: 100%;
  background-color: transparent;
}

.faxform input {
  border: 1px solid #3880ff;
  width: 100%;
  border-radius: 5px;
}

.faxform .css-13cymwt-control {
  border-color: #3880ff !important;
}

.ant-checkbox-group > label {
  width: 100%;
  margin-bottom: 10px;
}

/* ////form builder */
#fb-editor .clear-all {
  display: none;
}

#fb-editor .get-data {
  display: none;
}

#fb-editor .save-template {
  border-radius: 5px;
  margin-top: 15px;
}

#fb-editor- .form-wrap.form-builder .frmb-control li {
  display: none;
}

#fb-editor- .form-wrap.form-builder .stage-wrap {
  width: 100%;
  /* height: 100vh; */
}

#fb-editor- .clear-all {
  display: none;
}

#fb-editor- .get-data {
  display: none;
}

#fb-editor- .save-template {
  border-radius: 5px;
  margin-top: 15px;
}

#fb-editor- .empty {
  display: none;
}

#fb-editor- .form-wrap.form-builder .frmb .field-actions {
  opacity: 0 !important;
}

.DebounceInput {
  outline: none;
  border-radius: 10px;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1677ff;
}

.boxes {
  border-right: 1px solid #fff;
  padding: 1rem 1rem;
  color: #fff;
  min-width: 19rem;
  text-align: center;
  cursor: pointer;
}

.btn__height {
  min-height: 2.5rem;
}

.boxes:nth-of-type(1) {
  font-size: 5.5rem;
}

.boxes article {
  color: #fff;
}

.blue__text {
  color: #3880ff;
  font-weight: bold;
  font-size: 1rem;
}

.top__tab__container {
  display: flex;
  border-radius: 2rem;
  background-color: #e3eaef;
  justify-content: space-between;
}

.active {
  color: #fff;
  background-color: #3880ff;
  box-shadow: 0 2px 6px #7a6197;
}

.unactive {
  background-color: #dedede;
  box-shadow: 0 2px 6px #7a6197;
}

.chat__box__container {
  display: flex;
  flex: auto;
  gap: 1.5rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0.5rem;
}

.chat__box {
  max-height: 60rem;
  overflow-y: auto;
}

.patient__list__container {
  max-height: 29rem;
  overflow-y: auto;
}

.chat__sub {
  justify-content: space-between;
  align-items: center;
}

.filter__add__container {
  display: flex;
  gap: 1rem;
}

.borderRadius {
  border-radius: 2rem;
}

.button__height {
  min-height: 2.5rem;
  min-width: 3.5rem;
}

.button__height:hover {
  background-color: #3f5de0;
}

.check__grp > div {
  flex-direction: column;
}

.notes__list__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 10px;
  color: #fff;
  max-height: 90px;
  overflow: hidden;
}

.green__bg {
  background-color: #76a549;
}

.lightgray__bg {
  background-color: lightgray;
}

.card__white {
  background-color: #fff !important;
  margin: 0.5rem 0;
  padding: 1rem;
}

.slidebutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 50%;
}

.search_input {
  width: 100%;
  height: 40px !important;
  border: 1px solid #d8d6d6;
  padding-left: 10px;
}

.provider__patient__card {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* padding: 0.5rem; */
  margin: 0 0 1rem 0 !important;
  cursor: pointer;
  font-weight: 700 !important;
}

.dark__gray__text {
  color: #222;
}

.white__text {
  color: #fff;
}

.fs__600 {
  font-weight: 600;
}

.button__shadow__left {
  box-shadow: -8px 0 10px -4px rgba(0, 0, 0, 0.2);
}

.one__rem {
  font-size: 1rem;
}

.audit {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.patient__btn__container {
  display: flex;
  gap: 1rem;
}

.patient__btn {
  padding: 0.5rem;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.microPhone {
  background-color: #2fcf00;
}

.microPhone:hover {
  background-color: #41c457;
}

.dark__blue {
  background: #173f61;
  color: #fff;
}

.upload__doc {
  display: flex;
  gap: 5px;
}

.dark__green__bg {
  background-color: #28a745;
  color: #fff;
}

.tomato__red__bg {
  background-color: #fc544b;
  color: #fff;
}

.tomato__red__bg:hover {
  border: 1px solid;
}

.dark__green__bg:hover {
  background-color: #41c457;
  color: #fff;
}

.webCam__container {
  height: 200px;
  width: 100%;
  margin: auto;
}

.provider__footer {
  width: 100%;
  min-height: 6rem;
  display: flex;
  justify-content: space-between;
}

.provider__footer > div {
  border-right: 1px solid #fff;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.provider__footer > div:last-child {
  border-right: none;
}

.provider__footer > div > article {
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

/* Provider Forms Block */
.provider__form__slider {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 0.4rem 0;
}

.provider__form__slider > button {
  border: none !important;
}

.provider__form__ {
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
  max-height: 59vh;
  overflow-y: auto;
}

/* Form Inner bg */
.ant-collapse-content-box {
  background: #ebebeb;
}

.form.ant-checkbox-group.css-dev-only-do-not-override-12jzuas {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.allergies > .ant-form-item {
  margin-bottom: 10px !important;
}

/* graph */
.TotalpatientChartProvider select {
  border: 1px solid #3a80ff;
  border-radius: 5px;
  font-size: 1rem;
  background: transparent;
  outline: none !important;
  /* width: 13%; */
  padding: 3px;
}

.graphBackground {
  background-color: white;
  box-shadow: 0px 6px 10px 3px rgb(206, 202, 202);
}

.pollMain {
  display: flex;
  gap: 13px;
  max-width: 100vw;
  overflow-x: auto;
}

.poll_image {
  border-radius: 6px;
  text-align: center;
  /* max-width: 17%;
  min-width: 17%; */
}

.poll_image img {
  height: 140px;
  border-radius: 6px;
  /* width: 100%; */
}

.pollInputDiv input {
  width: 50%;
  padding: 5px 0px 5px 13px;
  outline: none;
  border: 1px solid #3a80ff;
  border-radius: 4px;
  font-size: 1.1rem;
  color: #515151;
}

/* scale */

.scaleMain {
  display: flex;
  gap: 13px;
  max-width: 100vw;
  overflow: auto;
}

.scale_image {
  border-radius: 6px;
  text-align: center;
}

.scale_image img {
  height: 80px;
  border-radius: 6px;
}

/* potential deomrphic graph */
.potentialMain {
  display: flex;
  gap: 13px;
  max-width: 100vw;
  overflow-x: auto;
}

.potential_image {
  border-radius: 6px;
  text-align: center;
}

.potential_image img {
  height: 120px;
  border-radius: 6px;
}

/* evalution graph */

.evalutionGraph {
  display: flex;
  gap: 13px;
  max-width: 100vw;
  overflow-x: auto;
}

.leftSideInfo {
  border-right: 1px solid #3a80ff;
  min-height: 100%;
  height: 300px !important;
  overflow-y: auto;
}

.rightData {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rightSideInfo {
  border-left: 1px solid #3a80ff;
  min-height: 100%;
}

.viewModalMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #626262;
}

.ant-steps-icon-dot {
  height: 0.95rem !important;
  width: 0.95rem !important;
}

.ant-steps-item-tail {
  top: 4px !important;
}

.ant-steps-item-icon {
  margin-inline-start: 0px !important;
}

.ant-steps-item-tail {
  margin-inline-start: 0px !important;
}

.ant-steps.ant-steps-dot .ant-steps-item-tail::after {
  height: 5px !important;
}

.ant-select-selector {
  height: auto !important;
  border: 1px solid #3881ff6c !important;
  overflow: auto;
  height: 40px !important;
  color: #000 !important;
}

/* //// calling */

/* CSS for the IncomingCallView component */
/* Add any additional CSS styles here if needed */

.incoming-call-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.incoming-call-container {
  text-align: center;
  position: absolute;
  bottom: 15px;
  right: 0;
  left: 0;
}

.incoming-call-modal .ant-modal-content {
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.incoming-call-text {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
}

.caller-text {
  color: black;
  font-size: 16px;
}

.button-group {
  margin-top: 20px;
}

.accept-button,
.decline-button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.decline-button {
  background-color: #f44336;
}

.incoming-call-modal .ant-modal-content {
  padding: 0;
}

.formDiv .ant-input-disabled {
  color: #4e4848 !important;
}

.dynamicFormMain input[disabled] {
  background-color: rgb(209 209 209);
}

.counter {
  font-size: 10px;
  right: 0;
  position: absolute;
  background: #08c808;
  width: 18px;
  border-radius: 50px;
  text-align: center;
  color: white;
  height: 18px;
  line-height: 1.8;
  top: 0;
}

.ant-badge-dot {
  width: 10px !important;
  height: 10px !important;
  background-color: rgb(231 231 231);
}

/* .ant-steps.ant-steps-dot .ant-steps-item-content,
:where(.css-dev-only-do-not-override-txh9fw).ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 100px !important;
} */

/* input type number arrow remove */
.familyMemberAddForm input::-webkit-outer-spin-button,
.familyMemberAddForm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.familyMemberAddForm input[type="number"] {
  -moz-appearance: textfield;
}

.ant-input-disabled {
  color: black !important;
}

.ant-select-disabled:where(.css-dev-only-do-not-override-txh9fw).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  color: rgb(0, 0, 0) !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-picker
  .ant-picker-input
  > input-disabled,
:where(.css-dev-only-do-not-override-txh9fw).ant-picker
  .ant-picker-input
  > input[disabled] {
  color: rgba(0, 0, 0) !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.85) !important;
}

.highlighted {
  background-color: lightblue !important;
}

.collapse-content {
  display: none;
  padding: 10px;
  background-color: #f5f5f5;
}

.collapse-row.open .collapse-content {
  display: block;
}

:where(.css-txh9fw).ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.8);
}

.icon-autocomplete,
.icon-button,
.icon-file,
.icon-hidden,
.icon-paragraph,
.icon-number {
  display: none;
}

/* For Step Last Tail */
:where(.css-dev-only-do-not-override-txh9fw).ant-steps
  .ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-tail,
:where(.css-dev-only-do-not-override-txh9fw).ant-steps
  .ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: block !important;
}

.ant-steps {
  gap: 2px;
}

.ant-steps-item-content {
  width: 100% !important;
}

.ant-title {
  font-size: 0.7rem;
}

.ant-steps-item-description {
  font-size: 0.6rem !important;
}

.ant-steps-item {
  width: 33.3%;
}

.ant-table-thead > th {
  background: linear-gradient(90deg, #104861, #3880ff) !important;
  color: #fff;
}

.ant-picker-input > input::placeholder {
  color: #8f9294 !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  max-width: 35rem !important;
}

.dropDwnGrey {
  font: 800 0.8rem "Nunito", sans-serif;
  color: grey;
}

.dropDwnBlue {
  color: #104861;
  font: 800 0.8rem "Nunito", sans-serif;
}

:where(.css-txh9fw).ant-steps
  .ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-tail,
:where(.css-txh9fw).ant-steps
  .ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: block !important;
}

#meeting_row .ant-table-measure-row {
  display: none !important;
}

.webCam__container video {
  width: 100%;
}

.agora_video_player {
  object-fit: contain !important;
}

.dyn-feild .ant-pagination-options {
  display: none;
}

.form-his-dis .ant-select-selector {
  height: auto !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #3881ff6c !important;
  height: 40px !important;
}

.react-tel-input .form-control {
  border: 1px solid #3881ff6c;
  height: 40px !important;
  width: 100% !important;
}

.react-tel-input {
  padding: 0 !important;
  height: 50px !important;
  border: none !important;
  border-radius: 5px;
}

.form-control:focus {
  box-shadow: none !important;
}

.empty-phone {
  border: 1px solid red !important;
  height: 45px !important;
}
.empty-phone .form-control {
  border: none !important;
}
.ant-select-item-option-content {
  white-space: break-spaces !important;
}

.ant-select-selection-item {
  white-space: break-spaces !important;
  line-height: inherit !important;
}

/* .graph-tab .common_btn2 {
  font: 800 0.95rem "Nunito", sans-serif !important;
  padding-left: 15px;
  padding-right: 15px;
} */

.calendar-ico {
  font-size: 25px;
  color: rgb(0, 136, 204);
  cursor: pointer;
  margin-bottom: 15px;
  justify-content: right;
  float: right;
  width: 50%;
}
.relate .ant-select-single {
  height: auto;
}

/* *******************************Plaid payments styling***************** */
.bank_list {
  border: 1px solid #000;
  padding: 0.8rem;
  border-radius: 5px;
}

.trauma-description .ant-select-selector{
  height: auto!important;
}

.notes-v .ant-select-selector{
  height: 40px !important;
}

.medselector .ant-select-selector{
  height: auto!important;
}

.task-mdl{
  width: 100%!important;
}
.labletext{
  color:#1677ff!important;
  font-weight:bold!important;
}

.dynamic_form .ant-select-selector{
  height: auto!important;
  min-height: 50px;
}

.file-facility .anticon-delete{
  display: none!important;
}

.ant-upload-list-item{
  display: grid!important;
}

.pointer{
  cursor: pointer;
}

