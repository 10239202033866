/* Responsivness  media queries $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
@media screen and (min-width: 1800px) {
  .extra_padding {
    padding: 2.5rem 1.5rem 2.5rem 1.5rem;
  }

  .header_div {
    padding: 0.8rem 0;
  }

  .user_img {
    width: 60px;
    height: 60px;
  }

  .logo_width {
    width: 60px;
  }

  .common_btn {
    height: 50px;
    font: 800 1rem "Nunito", sans-serif;
  }

  .common_btn2 {
    height: 50px;
    font: 800 1rem "Nunito", sans-serif;
  }

  .input_fields {
    height: 50px;
  }

  .react-tel-input .flag-dropdown {
    border: 1px solid #3881ff6c !important;
    height: 50px !important;
  }

  .react-tel-input .form-control {
    height: 50px !important;
  }

  .ant-select-selector {
    height: 50px !important;
  }

  .search_input {
    height: 50px !important;
  }
  .min-h {
    height: 35px !important;
  }

  .ant-select-multiple .ant-select-selector {
    height: 50px;
  }

  .profile_card {
    padding: 3rem 2.5rem;
  }

  .large_font {
    font: 800 1.5rem "Nunito", sans-serif;
  }

  .medium_font {
    font: 500 1rem "Nunito", sans-serif;
  }

  .small_font {
    font: 800 1rem "Nunito", sans-serif;
  }

  tr > th {
    font: 800 1rem "Nunito", sans-serif;
  }

  tr > td {
    font: 400 1rem "Nunito", sans-serif;
  }

  .card_div {
    height: 150px;
    padding-left: 15px;
  }

  .input_fields::placeholder {
    font-size: 1rem;
  }

  .card_height_manage {
    height: 60px;
  }

  .img_upload {
    border: 2px dashed #3881ff6c;
    padding: 5rem 0;
  }

  /* .common_content {
        height: calc(100vh - 150px);
    } */

  .vi__character {
    height: 80px !important;
  }

  .vi__container {
    gap: 20px !important;
  }

  .chat_user_img {
    width: 80px;
    height: 80px;
  }

  .image_manage {
    height: 550px;
  }

  .border_ {
    height: 50px;
  }

  .chat_header {
    padding: 1rem;
  }

  .send_msg {
    padding: 0.8rem;
  }

  .height_manage {
    height: 550px;
  }

  .player-wrapper {
    height: 550px;
  }

  .ant-title {
    font-size: 0.9rem;
  }

  .ant-steps-item-description {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 991px) {
  .display_none {
    display: none;
  }

  .tab_bar_div {
    width: 1000px;
  }

  .table_width {
    width: 1000px;
  }

  .footer_div {
    width: 100%;
  }

  .ant-drawer-content-wrapper {
    display: block;
  }

  .ant-drawer-mask {
    display: block;
  }

  .modal_width {
    width: 100% !important;
  }

  .boxes {
    min-width: 12rem !important;
  }

  .chat_div {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .logo_facilityHeader {
    width: 13% !important;
  }

  .chat_div {
    width: 45%;
    left: 15px !important;
  }
}

@media screen and (max-width: 550px) {
  .card_width_manage {
    width: 100%;
  }

  .sub_tab {
    width: 500px;
    margin-bottom: 0.5rem;
  }

  .boxes {
    min-width: 6rem !important;
  }

  .profile_card {
    padding: 1.5rem 1rem;
  }

  .common_form_card {
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .welcome_div > img {
    width: 25%;
  }

  .chat_div {
    width: 90%;
    height: 60vh;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .player-wrapper {
    height: 300px;
  }

  .image_manage {
    height: 350px;
  }
  
  .modal_wrapper {
    width: 100%;
  }
}
